import i18n from '../i18n'

export default function (code) {
  let r = '';
  switch (code) {
    case "0001": {
      r = i18n.t('error.0001');
      break;
    }
    case "BANK": {
        r = i18n.t('error.BANK');
      break;
    }
    case "BLACKLIST_PURCHASE": {
      r = i18n.t('error.BLACKLIST_PURCHASE');
      break;
    }
    case "INVALID_CVV": {
      r = i18n.t('error.INVALID_CVV');
      break;
    }
    case "INVALID_CLIENT_DATA": {
      r = i18n.t('error.INVALID_CLIENT_DATA');
      break;
    }
    case "DUPLICATE_PURCHASE": {
      r = i18n.t('error.DUPLICATE_PURCHASE');
      break;
    }
    case "PRODUCT_OUT_OF_STOCK": {
      r = i18n.t('error.PRODUCT_OUT_OF_STOCK');
      break;
    }
    case "CREDIT_CARD_OPERATOR": {
      r = i18n.t('error.CREDIT_CARD_OPERATOR');
      break;
    }
    case "INVALID_DATA": {
      r = i18n.t('error.INVALID_DATA');
      break;
    }
    case "WORLDPAY_CARD_ERROR": {
      r = i18n.t('error.WORLDPAY_CARD_ERROR');
      break;
    }
    case "INVALID_CREDIT_CARD": {
      r = i18n.t('error.INVALID_CREDIT_CARD');
      break;
    }
    case "INSUFFICIENT_FUNDS": {
      r = i18n.t('error.INSUFFICIENT_FUNDS');
      break;
    }
    case "INVALID_PAYMENT_TYPE": {
      r = i18n.t('error.INVALID_PAYMENT_TYPE');
      break;
    }
    case "INVALID_INSTALLMENTS": {
      r = i18n.t('error.INVALID_INSTALLMENTS');
      break;
    }
    case "CURRENCY_NOT_SUPPORTED": {
      r = i18n.t('error.CURRENCY_NOT_SUPPORTED');
      break;
    }
    case "SUSPECTED_FRAUD": {
      r = i18n.t('error.SUSPECTED_FRAUD');
      break;
    }
    case "GENERIC": {
      r = i18n.t('error.GENERIC');
      break;
    }

    // assinatura
    case '0404': {
      r = i18n.t('error.0404');
      break;
    }
    case '1404': {
      r = i18n.t('error.1404');
      break;
    }
    case '1405': {
      r = i18n.t('error.1405');
      break;
    }
    default: {
      r = i18n.t('error.GENERIC');
      break;
    }
  }
  return r
}